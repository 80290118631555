import { template as template_1b9ab447c7134ce5a57bfb322d2d56e5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_1b9ab447c7134ce5a57bfb322d2d56e5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
