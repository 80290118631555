import { template as template_4bbd84f27ee241a9b26976b8eb6bc2a5 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_4bbd84f27ee241a9b26976b8eb6bc2a5(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
