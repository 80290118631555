import { template as template_c4dd6cd41a1d4d96911eafc21537ae34 } from "@ember/template-compiler";
const WelcomeHeader = template_c4dd6cd41a1d4d96911eafc21537ae34(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
