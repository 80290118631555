import { template as template_76fa18a8ca5d4a99b674b6c770f74536 } from "@ember/template-compiler";
const FKLabel = template_76fa18a8ca5d4a99b674b6c770f74536(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
